<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const props = defineProps({
  categoryId: {
    type: Number,
    default: 143,
  },
});

const swiperOptions = {
  modules: [Pagination],
  pagination: {
    clickable: true,
  },
  breakpoints: {
    300: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 3,
    },
  },
  spaceBetween: 40,
};

const blogs = ref([]);
onMounted(async () => {
  try {
    const host = process.env.NODE_ENV === "development" ? "https://miraway.vn" : window.location.origin;
    const response = await $fetch(`${host}/blog/wp-json/wp/v2/posts`, {
      method: "GET",
      query: { categories: [ props.categoryId] },
    });
    blogs.value = response;
  } catch (error) {
    console.log(error);
  }
});

const formatDate = (date) => {
  const day = date.split("T")[0];
  const time = date.split("T")[1];

  return day.split("-").reverse().join("/") + " " + time;
};
</script>

<template>
  <section class="mt-[100px] max-md:mt-[80px] 2xl:container px-[120px]">
    <h2 class="text-primary text-center heading-3">
      <span class="heading-3 text-[#0066E6]">Bài viết </span>
      <span class="heading-3 text-[#00295C]">liên quan</span>
    </h2>

    <swiper class="mt-14 swiper-dot" v-if="blogs?.length" v-bind="swiperOptions">
      <swiper-slide v-for="(blog, index) in blogs" :key="index">
        <div class="h-full border border-solid border-[#E6E8EC] rounded-3xl flex flex-col bg-white">
          <a :href="blog.link" target="_blank">
            <nuxt-picture
              loading="lazy"
              :src="blog.featured_image_url"
              :img-attrs="{ class: 'w-full rounded-t-3xl', alt: `blog-${index + 1}` }"
            />
          </a>

          <div class="p-4">
            <a :href="blog.link" target="_blank">
              <div
                class="font-semibold uppercase leading-[1.5] text-primary line-clamp-2"
                v-html="blog.title.rendered"
              />
            </a>

            <div v-html="blog?.excerpt.rendered" class="text-large line-clamp-2 my-2" />

            <div class="text-[#777E90] text-extra-small">{{ formatDate(blog.date) }}</div>
          </div>

          <div class="text-right mt-auto">
            <a :href="blog.link" target="_blank">
              <button class="link px-4">Xem thêm</button>
            </a>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>
